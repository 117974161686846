import { render, staticRenderFns } from "./Supplement.vue?vue&type=template&id=af3c3edc&scoped=true&lang=pug&"
import script from "./Supplement.vue?vue&type=script&lang=js&"
export * from "./Supplement.vue?vue&type=script&lang=js&"
import style0 from "./Supplement.vue?vue&type=style&index=0&id=af3c3edc&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af3c3edc",
  null
  
)

export default component.exports